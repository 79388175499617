@import "https://fonts.googleapis.com/css?family=Fira+Mono";
@import "https://fonts.googleapis.com/css?family=Just+Me+Again+Down+Here";
@import url("https://fonts.googleapis.com/css2?family=Handjet&family=Teko:wght@900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    font-family: "Fira Mono", "Courier New", Courier, monospace;
    /* outline: #f0f solid 10px !important; */
}
body {
    width: 100vw;
    height: 100vh;
}
#root {
    width: 100vw;
    height: 100vh;
}
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0;
}

*::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
}
.input-number-hide-arrow::-webkit-outer-spin-button,
.input-number-hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}
