.canvas-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}
.canvas-container canvas {
  position: absolute;
  border: 1px solid #000;
}
.canvas-container .svg-container {
  position: absolute;
  z-index: 20;
  pointer-events: none;
}
.canvas-container .svg-container.active {
  pointer-events: all;
}
/*
.canvas-container canvas.cursor-brush02 {
  cursor: url("../../../icons/cursor-pencil-02-1x.png"), pointer;
  cursor: -webkit-image-set(
      url("../../../icons/cursor-pencil-02-1x.png") 1x,
      url("../../../icons/cursor-pencil-02-2x.png") 2x
    ),
    pointer;
}
.canvas-container canvas.cursor-brush03 {
  cursor: url("../../../icons/cursor-pencil-03-1x.png"), pointer;
  cursor: -webkit-image-set(
      url("../../../icons/cursor-pencil-03-1x.png") 1x,
      url("../../../icons/cursor-pencil-03-2x.png") 2x
    ),
    pointer;
}
.canvas-container canvas.cursor-brush04 {
  cursor: url("../../../icons/cursor-pencil-04-1x.png"), pointer;
  cursor: -webkit-image-set(
      url("../../../icons/cursor-pencil-04-1x.png") 1x,
      url("../../../icons/cursor-pencil-04-2x.png") 2x
    ),
    pointer;
}
.canvas-container canvas.cursor-brush05 {
  cursor: url("../../../icons/cursor-pencil-05-1x.png"), pointer;
  cursor: -webkit-image-set(
      url("../../../icons/cursor-pencil-05-1x.png") 1x,
      url("../../../icons/cursor-pencil-05-2x.png") 2x
    ),
    pointer;
}
.canvas-container canvas.cursor-eraser {
  cursor: url("../../../icons/cursor-eraser-1x.png"), pointer;
  cursor: -webkit-image-set(
      url("../../../icons/cursor-eraser-1x.png") 1x,
      url("../../../icons/cursor-eraser-2x.png") 2x
    ),
    pointer;
} */
